import React, { useEffect, useState } from 'react'
import dayjs from 'moment'
import { AuthStore } from '../../store/auth'
import api from '../../api'
import { Button, Col, Form, Row, Table, Typography } from 'antd'
import Flatpickr from 'react-flatpickr'
import styled from 'styled-components'
import theme from '../../theme'

const Badge = styled.div`
    background: unset;
    border: 1px solid #263db5;
    color: #263db5;
    font-weight: 500;
    padding: .5rem!important;
    margin: .25rem!important;
    border-radius: 10rem;
    display: inline-block;
    font-size: 0.75rem;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    box-sizing: border-box;
    font-family: inherit;
`

function HistoricalCheckin() {
    const authContext = AuthStore()

    const [checkinList, setCheckinList] = useState({
        loading: true,
        list: [],
        total: 0,
        page_total: 10,
        page: 0,
        start_date: dayjs(),
        end_date: dayjs(),
    })

    useEffect(() => {
        if (checkinList.loading) {
            api.checkins.list(checkinList.page, checkinList.page_total, checkinList.start_date.startOf('day').format('YYYY-MM-DD HH:mm:ss'), checkinList.end_date.endOf('day').format('YYYY-MM-DD HH:mm:ss')).then(result => {
                // console.log(result)
                setCheckinList({ ...checkinList, loading: false, list: result.data.list, total: result.data.total })
            })
        }
    }, [checkinList])

    return (
        <>
            <Typography style={{ fontSize: '1.25rem', marginBottom: '1rem' }}>
                Historical Check-in
            </Typography>
            <Form onFinish={() => setCheckinList({ ...checkinList, loading: true })} style={{ marginBottom: '1rem' }}>
                <Row>
                    <Col xs={24} md={8}>
                        <Flatpickr
                            options={{
                                time_24hr: true,
                                minuteIncrement: 15,
                                disableMobile: true,
                                mode: 'range',
                                enableTime: false,
                                dateFormat: 'd-m-Y',
                            }}
                            style={{
                                border: '1px solid #d9d9d9',
                                padding: '0.25rem 0.75rem',
                                width: '100%',
                                textAlign: 'center'
                            }}
                            defaultValue={dayjs().startOf('week').format('DD-MM-YYYY HH:mm:ss') + ' to ' + dayjs().endOf('week').format('DD-MM-YYYY HH:mm:ss')}
                            onChange={date => {
                                const start = dayjs(date[0]).startOf('day')
                                const end = date.length > 1 ? dayjs(date[1]).endOf('day') : dayjs(date[0]).endOf('day')

                                setCheckinList({ ...checkinList, start_date: start, end_date: end })
                            }}
                        />
                    </Col>
                    <Col xs={24} md={3}>
                        <Button block htmlType='submit'>Search</Button>
                    </Col>
                </Row>
            </Form>
            <Table
                scroll={{ x: 800 }}
                rowKey='id'
                dataSource={checkinList.list}
                loading={checkinList.loading}
                pagination={{
                    current: checkinList.page + 1,
                    total: checkinList.total,
                    pageSize: checkinList.page_total,
                    onChange(page, pageSize) {
                        setCheckinList({ ...checkinList, loading: true, page: page - 1, page_total: pageSize })
                    }
                }}
                columns={[
                    {
                        title: 'Date/Time',
                        align: 'center',
                        dataIndex: 'created_at',
                        width: 150,
                        render: (created_at, data) => {
                            return <>
                                <Typography>
                                    {dayjs(created_at).format('D-M-YYYY')}
                                </Typography>
                                <Typography>
                                    {dayjs(created_at).format('HH:mm')} - {dayjs(created_at).add(data.amount * 30, 'minutes').format('HH:mm')}
                                </Typography>
                            </>
                        }
                    },
                    {
                        title: 'Appropriate',
                        align: 'center',
                        width: 100,
                        dataIndex: 'status',
                        render: (status) => {
                            return status === 10 ?
                                (<Badge style={{ backgroundColor: theme.color.success, color: '#FFFFFF', border: 'none' }}>Lesson</Badge>)
                                : status === 0 ? <Badge style={{ backgroundColor: theme.color.primary, color: '#FFFFFF', border: 'none' }}>Practice</Badge>
                                    : <Badge style={{ backgroundColor: theme.color.info, color: '#FFFFFF', border: 'none' }}>Camp</Badge>
                        }
                    },
                    {
                        title: 'Amount(Hour)',
                        dataIndex: 'amount',
                        align: 'center',
                        width: 100,
                        render: (amount, data) => {
                            return data.status === 10 && amount > 0 && amount * 0.5
                        }
                    },
                    {
                        title: authContext.state.user.role_text === 'Teacher' ? 'Student' : 'Teacher',
                        width: 250,
                        align: 'center',
                        render: (data) => {
                            if (authContext.state.user.role_text === 'Teacher') {
                                return <Typography style={{ width: '100%', textAlign: 'left' }}>
                                    {data.user ? data.user.fullname_en || data.user.fullname_th : 'Non-Specify'}
                                </Typography>
                            }
                            return <Typography style={{ width: '100%', textAlign: 'left' }}>
                                {data.teacher ? data.teacher.fullname_en || data.teacher.fullname_th : 'Non-Specify'}
                            </Typography>
                        }
                    }
                ]}
            >
            </Table>
        </>
    )
}

export default HistoricalCheckin
