import { Drawer, Layout as AntdLayout, Menu, Dropdown, Typography } from 'antd';
import { FaHome, FaUserAlt } from 'react-icons/fa'
import styled from 'styled-components'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useState } from 'react';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { VscHome } from 'react-icons/vsc'
import { useMediaQuery } from 'react-responsive'
import { AuthActions, AuthStore } from '../store/auth';
const { Header, Content, Sider } = AntdLayout;

export default function Layout({ children }) {
    const history = useHistory()
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })
    const [collapse, setCollapse] = useState(false)
    const location = useLocation()

    const authContext = AuthStore()

    const menu = (
        <Menu>
            <Menu.Item onClick={() => { history.push('/profile') }}>Profile</Menu.Item>
            <Menu.Item onClick={() => { authContext.dispatch(AuthActions.logout()) }}>Sign Out</Menu.Item>
        </Menu>
    );

    return (
        <AntdLayout style={{ display: 'flex', flex: 1, backgroundColor: 'white' }}>
            <Header
                className="header"
                style={{
                    backgroundColor: 'white',
                    boxShadow: '0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04)',
                    height: isMobile ? '60px' : '80px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: isMobile ? '0px 1rem' : '0px 30px',
                    justifyContent: 'space-between'
                }}>
                {isMobile && (collapse ? (
                    <MenuUnfoldOutlined style={{ fontSize: '1.5rem' }} onClick={() => { setCollapse(false) }} />
                ) : (
                        <MenuFoldOutlined style={{ fontSize: '1.5rem' }} onClick={() => { setCollapse(true) }} />
                    ))}
                <Link to={'/'} style={{ width: isMobile ? 'auto' : '120px' }}>
                    <div
                        className="logo"
                        style={{
                            backgroundImage: `url('/logo.jpg')`,
                            height: isMobile ? '2rem' : '60px',
                            width: isMobile ? '2rem' : '60px',
                            backgroundSize: 'cover',
                            borderRadius: '50%'
                        }} />
                </Link>
                <Dropdown overlay={menu} trigger={['click']}>
                    <SettingOutlined style={{ fontSize: '1.5rem' }} />
                </Dropdown>
            </Header>
            <AntdLayout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
                <Sider
                    theme='light'
                    collapsed={isMobile}
                    width={120}
                    collapsedWidth={0}
                >
                    <SideMenu
                        style={{
                            height: '100%', borderRight: 0,
                        }}
                        selectedKeys={['home']}
                    >
                        <Menu.Item key='home' >
                            <Link to={`/`}>
                                <VscHome style={{ fontSize: '2rem', }} />
                                <p>Home</p>
                            </Link>
                            <Triangle className='triangle' style={{ display: location.pathname === '' || location.pathname === '/' ? 'block' : 'none' }} />
                        </Menu.Item>
                        <Menu.Item key='profile' >
                            <Link to={`/profile`}>
                                <FaUserAlt style={{ fontSize: '2rem', }} />
                                <Typography>Profile</Typography>
                            </Link>
                            <Triangle className='triangle' style={{ display: location.pathname === '/profile' ? 'block' : 'none' }} />
                        </Menu.Item>
                    </SideMenu>
                </Sider>
                <Content
                    style={{
                        padding: '2rem',
                        margin: 0,
                        minHeight: 280,
                        boxShadow: isMobile ? 'inset 0px 5px 10px rgba(0,0,0,0.04)' : 'inset 8px 8px 10px rgba(0,0,0,0.04)',
                    }}
                >
                    {children}
                </Content>
            </AntdLayout>
            <Drawer
                width={'25%'}
                closable={false}
                onClose={() => { setCollapse(false) }}
                visible={collapse}
                placement='left'
                bodyStyle={{
                    padding: '24px 2px',
                }}
            >
                <SideMenu
                    style={{
                        height: '100%',
                        borderRight: 0,
                    }}
                    selectedKeys={['home']}
                >
                    <Menu.Item key='home' >
                        <Link to={`/`}>
                            <FaHome style={{ fontSize: '2rem', }} />
                            <Typography>Home</Typography>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key='profile' >
                        <Link to={`/profile`}>
                            <FaUserAlt style={{ fontSize: '2rem', }} />
                            <Typography>Profile</Typography>
                        </Link>
                    </Menu.Item>
                </SideMenu>
            </Drawer>
        </AntdLayout>
    )
}

const SideMenu = styled(Menu)`
    overflow-x:hidden;
    li{
        color: #47404f;
        padding:0rem !important;
        text-align: center;
        min-height: 6rem;
        line-height: 1.25rem !important;
        border-color: #dee2e6;
        border-style: solid;
        border-width: 1px 0px 1px 0px;
        margin: 0px !important;
        .triangle{
            display: none;
        }
        a{
            display: block;
            height:100%;
            padding:1rem 0rem;
            color: #fed504;
            article{
                color: #fed504;
            }
        }
        &.ant-menu-item-selected{
            background-color:white !important;
            color: #fed504 !important;
            &::after{
                border:none;
            }
            .triangle{
                display: block;
            }
            a{
                color: #fed504 !important;
            }
        }
        :hover{
            a{
                color: #fed504;
            }
        }
        :active,:focus{
            background-color:white !important;
        }
    }

    @media (max-width:640px){
        li{
            min-height: 3rem;
            border:none;
            display:flex !important;
            height:auto !important;
            overflow:initial !important;
            justify-content:center;
            border-color:#dee2e6;
            border-style:solid;

            border-width:1px 0px 1px 0px;

            &:first-child{
                border-width:1px 0px 0px 0px !important;
            }
            /* border-bottom: 2px solid #fed504; */
            /* border-bottom: 1px solid #d9d9d9; */
            .triangle{
                display: none;
            }
            a{
                padding:0.5rem;
            }
        }        
    }
`

const Triangle = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    border-style: solid;
    border-width: 0 0 30px 30px;
    border-color: transparent transparent #fed504;
`