import React, { createContext, useContext, useEffect, useReducer } from "react"
import api from "../api"
import Api from '../api'
import * as auth from '../auth'

const initialState = {
    "user": {
        "id": null,
        "email": "",
        "tel": "",
        "fullname_th": "",
        "fullname_en": "",
        "dob": null,
        "img": "",
        "gender": null,
        "occupation": null,
        "address": null,
        "line_id": null,
        "prefer_contact": null,
        "type_name": null,
        "status": 0,
        "level": 0,
        "session": 0,
        "register_type": null,
        "reference_id": null,
        "gender_text": "",
        "prefer_text": "",
        role_text: '',
    },
    token: auth.getCurrentToken(),
    expired: auth.getExpired(),
    login: auth.getLogin(),
    loading: true,
    line_connect_token: '',
}

export const AuthActions = {
    login({ email, password }) {
        return Api.auth.login(email, password).then((result) => {
            // console.log(result)
            if (result.data) {
                auth.setLogin(result.data.access_token, result.data.expired * 1000)
            }

            return {
                token: result.data.access_token,
                expired: result.data.expired * 1000,
                user: result.data.user,
                login: true,
                loading: false,
            }
        })
    },
    forgot({ email }) {
        return Api.auth.forgot(email)
    },
    reset({ email, password, token }) {
        return Api.auth.reset(email, password, token)
    },
    me() {
        return Api.auth.me().then(result => {
            return {
                user: result.data,
                loading: false,
            }
        })
    },
    refreshToken(token) {
        return Api.auth.token(token).then((result) => {
            console.log(result)
            if (result.data) {
                auth.setLogin(result.data.access_token, result.data.expired * 1000)
            }

            return {
                token: result.data.access_token,
                expired: result.data.expired * 1000,
                user: result.data.user,
                login: true,
                loading: false,
            }
        }).catch(err => {
            if (err.message === 'Unauthorized') {
                return this.logout()
            }
        })
    },
    logout() {
        auth.logout()

        return {
            user: null,
            token: null,
            expired: null,
            login: false,
            loading: false,
        }
    },
    lineConnect(token) {
        return api.line.connect(token).then(() => {
            return {
                line_connect_token: '',
            }
        })
    }
}

function reducers(state, action) {
    return {
        ...state,
        ...action,
    }
}

const Context = createContext(initialState)

/**
 * @typedef  AuthContext
 * @property { initialState } state
 * @property { import('react').Dispatch } dispatch
 * 
 * @returns { AuthContext }
 */
export function AuthStore() {
    return useContext(Context)
}

export function Provider({ children }) {
    const [state, dispatch] = useReducer(reducers, initialState)

    useEffect(() => {
        if (state.login) {
            if (Number(state.expired) > Number(new Date())) {
                const expire = Number(state.expired) - Number(new Date()) - 30000

                AuthActions.me().then(async result => {
                    dispatch(result)
                    if (expire <= 0) {
                        // console.log('refreshToken')
                        AuthActions.refreshToken(state.token).then(result => {
                            dispatch(result)
                        })
                    } else {
                        const timeout = setTimeout(() => {
                            // console.log('refreshToken with timeout')
                            AuthActions.refreshToken(state.token).then(result => {
                                dispatch(result)
                            })
                        }, expire)

                        dispatch({ loading: false })

                        return () => {
                            clearTimeout(timeout)
                        }
                    }
                    return null
                }).catch(() => {
                    dispatch(AuthActions.logout())
                })
            } else {
                dispatch(AuthActions.logout())
            }
        } else {
            dispatch({ loading: false })
        }
    }, [state.login, state.expired, state.token])

    return (<Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>)
}