import { Spin } from 'antd';
import React, { useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Layout from './containers/Layout';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import { AuthStore } from './store/auth';
import { BookingActions, BookingStore } from './store/bookings';
import theme from './theme';
import moment from 'moment'
import ForgotScreen from './screens/ForgotScreen';
import ResetScreen from './screens/ResetScreen';
import ProfileScreen from './screens/ProfileScreen';
import RegisCalendarScreen from './screens/RegisCalendarScreen';
import LineConntectScreen from './screens/LineConntectScreen';
import BookingEditScreen from './screens/Booking/BookingEditScreen';
import BookingCreateScreen from './screens/Booking/BookingCreateScreen';

function App() {
  const { state: authState } = AuthStore()

  const { dispatch: bookingDispach } = BookingStore()

  useEffect(() => {
    if (authState.login) {
      BookingActions.count(
        moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        moment().add(1, 'year').endOf('day').format('YYYY-MM-DD HH:mm:ss')
      ).then(result => {
        bookingDispach(result)
      })
    }
  }, [bookingDispach, authState])

  if (authState.loading) {
    return (<div style={{ alignItems: 'center', width: '100%', justifyContent: 'center', display: 'flex', height: '100vh' }}>
      <Spin size='large' />
    </div>)
  }

  if (!authState.login) {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path='/' exact component={LoginScreen} />
            <Route path='/forgot' exact component={ForgotScreen} />
            <Route path='/reset' exact component={ResetScreen} />
            <Route path='/notify/connect' exact component={LineConntectScreen} />
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    )
  }

  console.log(authState?.user?.role_text);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path='/notify/connect' exact component={LineConntectScreen} />
          <Layout>
            <Route path='/' exact component={HomeScreen} />
            {['Teacher','Admin'].some(w => w === authState?.user?.role_text)  && (
              <>
                <Route path='/bookings' exact component={BookingCreateScreen} />
                <Route path='/bookings/:id' exact component={BookingEditScreen} />
              </>
            )}
            <Route path='/profile' exact component={ProfileScreen} />
            <Route path='/registration-calendar' exact component={RegisCalendarScreen} />
          </Layout>
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
