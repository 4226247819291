import api from '../api'

export async function getToken(force = false) {
    if (+getExpired() < +new Date()) {
        return null
    }

    let token = getCurrentToken()

    if (force || (Number(getExpired()) - (10 * 60 * 1000)) <= Number(new Date())) {
        const res = await api.auth.token(token)

        token = res.data.access_token

        setLogin(res.data.access_token, token, res.data.expired * 1000)
    }

    return token
}

export function getExpired() {
    return localStorage.getItem('expired')
}

export function getCurrentToken() {
    return localStorage.getItem('token')
}

export function getLogin() {
    const expired = getExpired()

    if (+expired < +new Date()) {
        return false
    }

    return true
}

export function setLogin(token, expired) {
    localStorage.setItem('expired', expired)
    localStorage.setItem('login', true)
    localStorage.setItem('token', token)
}

export function logout() {
    localStorage.clear()
    return true
}