import React, { createContext, useContext, useReducer } from "react"
import Api from '../api'

const initialState = {
    total: 0,
    loading: true
}

export const BookingActions = {
    count(start_date, end_date) {
        return Api.bookings.count(start_date, end_date).then(result => {
            return {
                ...result.data,
                loading: false,
            }
        })
    },
}

function reducers(state, action) {
    return {
        ...state,
        ...action,
    }
}

const Context = createContext(initialState)

/**
 * @typedef  BookingContext
 * @property { initialState } state
 * @property { import('react').Dispatch } dispatch
 * 
 * @returns { BookingContext }
 */
export function BookingStore() {
    return useContext(Context)
}

export function Provider({ children }) {
    const [state, dispatch] = useReducer(reducers, initialState)

    return (<Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>)
}