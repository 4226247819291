import { Avatar, Card, Form, Layout, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AuthActions, AuthStore } from '../store/auth'

const LogoImage = '/logo.jpg'

const LoginCard = styled(Card)`
    overflow:initial;
    justify-content:center;
    align-self: center;
    width:75%;
    border-radius:0.5rem;
    padding:0rem;
    text-align:-webkit-center;

    @media (min-width:992px){
        width:42rem;
    }
`

const LoginForm = styled(Form)`
    text-align: center;
    max-width: 18rem ;
`

const FormTitle = styled(Typography)`
    font-size:1.15rem;
    font-weight:400;
    text-align:center;
    margin:1rem 0rem;
`

function LineConntectScreen() {
    const [init, setInit] = useState(true)
    const { state } = AuthStore()
    const history = useHistory()
    const params = new URLSearchParams(history.location.search)
    const token = params.get('token')

    useEffect(() => {
        if (init) {
            setInit(false)
            if (state.login) {
                AuthActions.lineConnect(token).then(() => {
                    history.replace('/')
                })
            } else {
                history.replace('/')
            }
        }
    }, [state, history, init, token])

    return (
        <Layout style={{
            background: '#FFFFFF',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            minHeight: '100vh',
            justifyContent: 'center'
        }}>
            <LoginCard>
                <LoginForm
                    name="normal_login"
                    className="login-form"
                >
                    <Avatar
                        shape='square'
                        src={LogoImage}
                        style={{
                            minWidth: '8rem',
                            minHeight: '8rem',
                        }}
                    />
                    <FormTitle>Connect line notify to server</FormTitle>
                    <Spin />
                </LoginForm>
            </LoginCard>
        </Layout>
    );
}

export default LineConntectScreen
