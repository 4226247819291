import { Col, Row, Spin, Typography } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
    GoCalendar,
} from 'react-icons/go';
import api from '../../api';
import { BookingStore } from '../../store/bookings';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import BookingForm from './BookingForm'

const BookingButton = styled(Link)`
    min-height: 6rem;
    border: none;
    border-radius:10px;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
    text-align:left;
    padding: 1.5rem;
    width:100%;
    height:100%;
    display:block;
    /* padding:2rem; */
`

function BookingCreateScreen(props) {
    const history = useHistory()

    const { state: bookinState } = BookingStore()

    const [saving, setSaving] = useState(false)

    const handleSubmit = (form) => {

        setSaving(true)

        api.bookings.create(form).then(res => {
            setSaving(false)
            Swal.fire({
                title: 'Success',
                icon: 'success',
                timer: 1000,
            }).then(() => {
                history.push('/')
            })
        }).catch(err => {
            setSaving(false)

            Swal.fire({
                icon: 'warning',
                title: 'Cannot Booking.',
                text: err.response.data.message,
            })
        })
    }

    return (
        <Row>
            <Col xs={24}>
                <Row>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <BookingButton to={'/bookings'}>
                            <div style={{ display: 'flex' }}>
                                <GoCalendar style={{ fontSize: '4rem', color: 'rgba(102,51,153,.28)' }} />
                                <div style={{ padding: '0rem 0.5rem', display: 'block' }}>
                                    <Typography style={{ color: '#70657b', fontSize: '1rem' }}>
                                        Booking
                                    </Typography>
                                    <Typography style={{ color: '#fed504', fontSize: '1.5rem' }}>
                                        {bookinState.loading ? (
                                            <Spin />
                                        ) : (
                                                <>{bookinState.total}</>
                                            )}
                                    </Typography>
                                </div >
                            </div>
                        </BookingButton>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <BookingForm id={props?.match?.params?.id} saving={saving} onSubmit={handleSubmit} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default BookingCreateScreen
