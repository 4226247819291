import { Badge, Button, Card, Col, Form, Radio, Row, Select, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { AuthStore } from '../../store/auth'
import Flatpickr from "react-flatpickr"
import dayjs from 'moment'
import theme from '../../theme';
import api from '../../api';
import Swal from 'sweetalert2'
import AsyncSelect from 'react-select';

const Content = styled(Card)`
    margin-top:1rem;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
    border: none;
    border-radius:10px;
`

function BookingForm({ id, onSubmit, saving }) {
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })

    const [loading, setLoading] = useState(true)

    const { state: authState } = AuthStore()

    const [state, setState] = useState({
        type: 10,
        begined_at: dayjs().startOf('hour').add(1, 'hour'),
        begined_at_hour: '',
        begined_at_min: '',
        finished_at: dayjs().startOf('hour').add(1, 'hour'),
        finished_at_hour: '',
        finished_at_min: '',
        amount: 1,
        user_id: null,
        studio: null,
    })

    const [bookingList, setBookingList] = useState({
        loading: true,
        list: [],
        total: 0,
        page_total: 9999,
        page: 0,
        start_date: dayjs(),
        curr_date: dayjs(),
        end_date: dayjs(),
    })

    const handleSubmit = () => {
        const stateBeginedAt = dayjs(state.begined_at.toDate()).startOf('day').add(Number(state.begined_at_hour), 'hours').add(Number(state.begined_at_min), 'minutes')
        const stateFinishedAt = state.type === 10 ? dayjs(state.begined_at.toDate()).startOf('day').add(Number(state.begined_at_hour), 'hours').add(Number(state.begined_at_min), 'minutes').add(state.amount * 30, 'minutes') : dayjs(state.finished_at.toDate()).startOf('day').add(Number(state.finished_at_hour), 'hours').add(Number(state.finished_at_min), 'minutes')

        const data = state.type === 10 ? {
            type: 10,
            begined_at: dayjs(state.begined_at.toDate()).startOf('day').add(Number(state.begined_at_hour), 'hours').add(Number(state.begined_at_min), 'minutes').format('YYYY-MM-DD HH:mm:ss'),
            finished_at: dayjs(state.begined_at.toDate()).startOf('day').add(Number(state.begined_at_hour), 'hours').add(Number(state.begined_at_min), 'minutes').add(state.amount * 30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
            amount: state.amount,
            user_id: state.user_id,
            studio: state.studio || undefined,
        } : {
            type: 1,
            begined_at: dayjs(state.begined_at.toDate()).startOf('day').add(Number(state.begined_at_hour), 'hours').add(Number(state.begined_at_min), 'minutes').format('YYYY-MM-DD HH:mm:ss'),
            finished_at: dayjs(state.finished_at.toDate()).startOf('day').add(Number(state.finished_at_hour), 'hours').add(Number(state.finished_at_min), 'minutes').format('YYYY-MM-DD HH:mm:ss'),
            amount: 0,
        }

        if (data.type === 10 && !data.user_id) {
            return Swal.fire({
                title: 'Student is required.',
                icon: 'warning',
            })
        }

        if (data.type === 1 && Number(stateBeginedAt.toDate()) > Number(stateFinishedAt.toDate())) {
            return Swal.fire({
                title: 'Cannot booking',
                text: 'End Time is greater Booking Time only.',
                icon: 'warning',
            })
        } else if (data.type === 1 && Number(stateBeginedAt.toDate()) === Number(stateFinishedAt.toDate())) {
            return Swal.fire({
                title: 'Cannot booking',
                text: 'Please set End Time',
                icon: 'warning',
            })
        }

        onSubmit(data)
    }

    const onDayCreate = (dObj, dStr, fp, dayElem) => {
        dayElem.innerHTML = dayElem.innerText

        if (bookingList.list.some(d => dayjs(d.begined_at).format('MMMM D, YYYY') === dayElem.getAttribute('aria-label') && dayjs(d.begined_at).unix() >= dayjs().startOf('day').unix())) {
            dayElem.innerHTML += "<span class='event' style='position: absolute;width: 5px;height: 5px;border-radius: 50%;top: 8px;left: calc(70% - 1.5px);content: \" \";display: block;background: #FED504;'></span>";
        }
    }

    useEffect(() => {
        if (bookingList.loading) {
            const startDate = bookingList.start_date.startOf('month').unix() >= dayjs().startOf('day').unix() ? bookingList.start_date.startOf('month') : dayjs().startOf('day')

            api.bookings.list(bookingList.page, bookingList.page_total, startDate.format('YYYY-MM-DD HH:mm:ss'), bookingList.end_date.endOf('month').format('YYYY-MM-DD HH:mm:ss'), 'booked').then(result => {

                setBookingList({ ...bookingList, loading: false, list: result.data.list, total: result.data.total })
            })
        }
    }, [bookingList])


    useEffect(() => {
        if (id) {
            api.bookings.detail(id).then(res => {
                const begined_at = dayjs(res.data.begined_at)
                const finished_at = dayjs(res.data.finished_at)
                setState({
                    ...res.data,
                    begined_at: begined_at,
                    begined_at_hour: begined_at.format('H'),
                    begined_at_min: begined_at.format('m'),
                    finished_at: finished_at,
                    finished_at_hour: finished_at.format('H'),
                    finished_at_min: finished_at.format('m'),
                    user_id: res.data.student?.user?.id || null,
                })
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [id])

    return (
        <Content style={{ padding: isMobile ? '0rem' : '1rem' }}>
            <Row>
                <Col xs={24} style={{ borderBottom: `1px solid #EEEEEE`, paddingBottom: '1rem' }}>
                    <div style={{ display: 'flex' }}>
                        <Typography style={{ marginRight: '0.5rem', fontSize: '1rem' }}>
                            Booking :
                        </Typography>
                        <Typography style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                            {authState.user.fullname_en}
                        </Typography>
                    </div>
                </Col>
            </Row>
            {loading ? (
                <Row>
                    <Col xs={24} style={{ width: '100%', padding: '4rem 0rem', textAlign: '-webkit-center' }}>
                        <Spin />
                    </Col>
                </Row>
            ) : (
                <Row style={{ padding: isMobile ? '0rem' : '1rem' }}>
                    <Col xs={24} md={10} style={{ padding: isMobile ? '0rem' : '1rem 0rem' }}>
                        <Card bordered={isMobile} bodyStyle={{ padding: isMobile ? '1rem' : '1rem 1rem 1rem 0rem' }}>
                            <Card.Meta title={`Timeline : ${state.begined_at.format('DD-MM-YYYY')}`} style={{ marginBottom: '1rem' }} />
                            <Row>
                                <Col xs={24} style={{ minHeight: '12rem', maxHeight: isMobile ? '12rem' : '20rem', overflowY: 'auto' }}>
                                    {bookingList.list.filter(l => dayjs(l.begined_at).format('YYYY-MM-DD') === state.begined_at.format('YYYY-MM-DD')).sort((a, b) => Number(dayjs(a.begined_at).toDate()) - Number(dayjs(b.begined_at).toDate())).map(l => {
                                        return (
                                            <Row key={l.id} style={{ padding: isMobile ? '1rem 0rem' : '0.5rem 2rem 0.5rem 1rem', borderBottom: isMobile ? '1px solid #CFCFCF' : 'none' }}>
                                                <Col xs={12}>
                                                    <Typography>
                                                        {l.type === 10 ? `${l.student.user.fullname_en}${l.student.user.fullname_th ? ` (${l.student.user.fullname_th})` : ''}` : 'Block'}
                                                    </Typography>
                                                </Col>
                                                <Col xs={12} style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                    <Badge style={{ backgroundColor: l.type === 10 ? theme.color.blue : theme.color.danger }} count={`${dayjs(l.begined_at).format('HH:mm')} - ${dayjs(l.finished_at).format('HH:mm')}`} />
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={14} style={{ padding: isMobile ? '0rem' : '1rem', borderLeft: isMobile ? 'none' : '1px solid #EEEEEE' }}>
                        <Form onFinish={handleSubmit} style={{ padding: '1rem' }}>
                            <Row>
                                <Col xs={24}>
                                    <Radio.Group
                                        value={state.type}
                                        onChange={(e) => { setState({ ...state, type: e.target.value }) }}
                                    >
                                        <Radio value={10} disabled={id}>Booking</Radio>
                                        <Radio value={1} disabled={id}>Rest</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col xs={24} style={{ marginTop: '1rem' }}>
                                    <Typography>Booking Date</Typography>
                                    <Flatpickr
                                        options={{
                                            time_24hr: true,
                                            minDate: 'today',
                                            minuteIncrement: 15,
                                            // enableTime: true,
                                            disableMobile: true,
                                            dateFormat: 'd-m-Y',
                                            onDayCreate,
                                        }}
                                        style={{ border: '1px solid #d9d9d9', padding: '0.25rem 0.75rem', width: '100%' }}
                                        defaultValue={state.begined_at ? dayjs(state.begined_at).format('DD-MM-YYYY') : dayjs().startOf('hour').add(1, 'hour').format('DD-MM-YYYY')}
                                        value={state.begined_at ? dayjs(state.begined_at).format('DD-MM-YYYY') : dayjs().startOf('hour').add(1, 'hour').format('DD-MM-YYYY')}
                                        onChange={date => {
                                            const value = dayjs(date[0])
                                            if (state.begined_at.format('YYYY-MM') !== value.format('YYYY-MM')) {
                                                setBookingList({ ...bookingList, start_date: dayjs(date[0]).subtract(1, 'months'), end_date: dayjs(date[0]).add(1, 'months'), loading: true })
                                            }

                                            if (state.type === 1) {
                                                if (Number(value.toDate()) >= Number(state.finished_at.toDate())) {
                                                    setState({ ...state, begined_at: value, finished_at: value })
                                                } else {
                                                    setState({ ...state, begined_at: value })
                                                }
                                            } else {
                                                setState({ ...state, begined_at: value })
                                            }

                                        }}
                                    />
                                </Col>
                                <Col xs={24} style={{ marginTop: '1rem' }}>
                                    <Row>
                                        <Col xs={12}>
                                            <select
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #CFCFCF',
                                                    padding: '5px'
                                                }}
                                                value={state.begined_at_hour}
                                                onChange={(e) => {
                                                    setState({ ...state, begined_at_hour: e.target.value })
                                                }}
                                                required
                                            >
                                                <option value="">Hour</option>
                                                {(new Array(24)).fill(null).map((v, i) => <option key={i} value={i}>{('0' + i).slice(-2)}</option>)}
                                            </select>
                                        </Col>
                                        <Col xs={12}>
                                            <select
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #CFCFCF',
                                                    padding: '5px'
                                                }}
                                                value={state.begined_at_min}
                                                onChange={(e) => {
                                                    setState({ ...state, begined_at_min: e.target.value })
                                                }}
                                                required
                                            >
                                                <option value="">Min.</option>
                                                {(new Array(4)).fill(null).map((v, i) => <option key={i} value={i * 15}>{('0' + (i * 15)).slice(-2)}</option>)}
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                                {state.type === 10 ? (
                                    <>
                                        <Col xs={24} style={{ marginTop: '1rem' }}>
                                            <Typography>Amount(Hour)</Typography>
                                            <Select style={{ width: '100%' }} value={state.amount} onChange={e => {
                                                const value = dayjs(state.begined_at).add(e * 30, 'minutes')

                                                setState({ ...state, amount: e, finished_at: value })
                                            }}>
                                                {new Array(10).fill(null).map((v, i) => {
                                                    return <Select.Option key={i} value={(i + 1)}>{Number((i + 1) * 0.5).toFixed(1)}</Select.Option>
                                                })}
                                            </Select>
                                        </Col>
                                        <Col xs={24} style={{ marginTop: '1rem' }}>
                                            <Typography>Student</Typography>
                                            <Student value={state.user_id} onChange={(e) => {
                                                setState({ ...state, user_id: e })
                                            }} />
                                        </Col>
                                        <Col xs={24} style={{ marginTop: '1rem' }}>
                                            <Typography>Studio</Typography>
                                            <Select style={{ width: '100%' }} value={state.studio} onChange={(e) => {
                                                setState({ ...state, studio: e })
                                            }}>
                                                <Select.Option value={null}>Non-specify</Select.Option>
                                                <Select.Option value={1}>Swing #1</Select.Option>
                                                <Select.Option value={2}>Swing #2</Select.Option>
                                                <Select.Option value={3}>Putting #1</Select.Option>
                                                <Select.Option value={4}>Putting #2</Select.Option>
                                            </Select>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col xs={24} style={{ marginTop: '1rem' }}>
                                            <Typography>End Date</Typography>
                                            <Flatpickr
                                                options={{
                                                    time_24hr: true,
                                                    minDate: 'today',
                                                    minuteIncrement: 15,
                                                    // enableTime: true,
                                                    disableMobile: true,
                                                    dateFormat: 'd-m-Y',
                                                    onDayCreate,
                                                }}
                                                style={{ border: '1px solid #d9d9d9', padding: '0.25rem 0.75rem', width: '100%' }}
                                                defaultValue={state.finished_at ? dayjs(state.finished_at).format('DD-MM-YYYY') : dayjs().startOf('hour').add(1, 'hour').format('DD-MM-YYYY')}
                                                value={state.finished_at ? dayjs(state.finished_at).format('DD-MM-YYYY') : dayjs().startOf('hour').add(1, 'hour').format('DD-MM-YYYY')}
                                                onChange={date => {
                                                    const value = dayjs(date[0])
                                                    if (state.type === 1) {
                                                        if (Number(state.begined_at.toDate()) >= Number(value.toDate())) {
                                                            setState({ ...state, begined_at: value, finished_at: value })
                                                        } else {
                                                            setState({ ...state, finished_at: value })
                                                        }
                                                    } else {
                                                        setState({ ...state, finished_at: value })
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24} style={{ marginTop: '1rem' }}>
                                            <Row>
                                                <Col xs={12}>
                                                    <select
                                                        style={{
                                                            width: '100%',
                                                            border: '1px solid #CFCFCF',
                                                            padding: '5px'
                                                        }}
                                                        value={state.finished_at_hour}
                                                        onChange={(e) => {
                                                            setState({ ...state, finished_at_hour: e.target.value })
                                                        }}
                                                        required
                                                    >
                                                        <option value="">Hour</option>
                                                        {(new Array(24)).fill(null).map((v, i) => <option key={i} value={i}>{('0' + i).slice(-2)}</option>)}
                                                    </select>
                                                </Col>
                                                <Col xs={12}>
                                                    <select
                                                        style={{
                                                            width: '100%',
                                                            border: '1px solid #CFCFCF',
                                                            padding: '5px'
                                                        }}
                                                        value={state.finished_at_min}
                                                        onChange={(e) => {
                                                            setState({ ...state, finished_at_min: e.target.value })
                                                        }}
                                                        required
                                                    >
                                                        <option value="">Min.</option>
                                                        {(new Array(4)).fill(null).map((v, i) => <option key={i} value={i * 15}>{('0' + (i * 15)).slice(-2)}</option>)}
                                                    </select>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                                <Col xs={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                                    <SubmitButton loading={saving} disabled={loading} htmlType='submit' block={isMobile}>Save</SubmitButton>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            )}
        </Content>
    )
}

const SubmitButton = styled(Button)`
    background-color: ${() => theme.color.danger};
    color: #FFFFFF;
    border: 1px solid ${() => theme.color.danger};
    border-radius: 0.25rem;

    &:hover{
        background-color: #FFFFFF;
        color: ${() => theme.color.danger};
        border: 1px solid ${() => theme.color.danger};
    }
`

const Student = ({ onChange, value }) => {
    const [loading, setLoading] = useState(false)

    const [result, setResult] = useState([]);

    const [optionValue, setOptionValue] = useState({
        value,
        label: value ? 'Loading...' : 'Select...',
        session: null,
    })

    useEffect(() => {
        if (value) {
            api.users.detail(value).then(res => {
                setOptionValue({
                    value: res.data.id,
                    label: res.data?.fullname_en && res.data?.fullname_th ? `${res.data?.fullname_en} (${res.data?.fullname_th})` : res.data?.fullname_en || res.data?.fullname_th,
                    session: res.data.session,
                })

                setResult([{
                    value: res.data.id,
                    label: res.data?.fullname_en && res.data?.fullname_th ? `${res.data?.fullname_en} (${res.data?.fullname_th})` : res.data?.fullname_en || res.data?.fullname_th,
                    session: res.data.session,
                }])
            })
        }
    }, [value])

    return (<>
        <AsyncSelect cacheOptions defaultOptions options={result} value={optionValue} isLoading={loading} onChange={e => {
            setOptionValue({
                value: e.value,
                label: e.label,
                session: e.session,
            })

            onChange(e.value)
        }} onInputChange={e => {
            if (e.length > 3) {
                setLoading(true)
                api.users.search(e).then(res => {
                    setResult(res.data.list.map(l => ({
                        value: l.id,
                        label: l.fullname_en && l.fullname_th ? `${l.fullname_en} (${l.fullname_th})` : l.fullname_en || l.fullname_th,
                        session: l.session,
                    })))
                    setLoading(false)
                })
            }
        }} />
        {optionValue.session !== null && optionValue.session >= 0 ? (
            <Typography style={{ color: optionValue.session ? 'blue' : 'red', display: 'inline-flex' }}>
                Session Remain : <Typography style={{ color: optionValue.session ? 'blue' : 'red', paddingLeft: '0.5rem' }}>{`${optionValue.session * 0.5} Hour${optionValue.session > 2 ? 's' : ''}`}</Typography>
            </Typography>
        ) : ''}
    </>)
}

export default BookingForm
