import axios from 'axios'
import env from '../config'
import * as auth from '../auth'

export async function client() {
    const token = await auth.getToken()
    return await axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/calendar/client`,
        // data: { email, password }
    })
}

export async function setClientId(code) {
    const token = await auth.getToken()
    return await axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/calendar/client/id`,
        data: { code }
        // data: { email, password }
    })
}

export async function clear() {
    const token = await auth.getToken()
    return await axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/calendar/client/clear`,
        // data: { email, password }
    })
}