import axios from 'axios'
import env from '../config'
import * as auth from '../auth'

export async function list(page, page_total, start_date, end_date) {
    const token = await auth.getToken()
    return axios({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/teachers/checkins`,
        params: { page, page_total, start_date, end_date }
        // data: { email, password }
    })
}