const theme = {
    dark: true,
    color: {
        primary: '#fed504',
        primaryHover: '#e0a800',
        success: '#237804',
        danger: '#d22346',
        light: '#FFFFFF',
        dark: '#111111',
        grey: '#999999',
        man: '#10239e',
        woman: '#9e1068',
        mute: '#70657b',
        info: '#a679d2',
        blue: '#2839b5',
        disabled: '#999999',
    },
}
export default theme