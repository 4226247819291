// import { Spin } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
// import api from '../api'

function RegisCalendarScreen() {
    const history = useHistory()

    const params = new URLSearchParams(history.location.search)

    const code = params.get('code')

    // useEffect(() => {
    //     if (code) {
    //         api.calendar.setClientId(code).then(res => {
    //             window.location.replace(`${window.location.origin}/profile`)
    //         })
    //     } else {
    //         history.replace('/')
    //     }
    // }, [code, history])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '4rem 0rem' }}>
            {code}
            {/* <Spin size='large' /> */}
        </div>
    )
}

export default RegisCalendarScreen
