import React from 'react'
import * as Auth from './auth'
import * as Booking from './bookings'

const providers = [
    Booking,
    Auth,
]

export function StoreProvider({ children }) {

    providers.forEach(provider => {
        children = <provider.Provider>{children}</provider.Provider>
    })

    return children
}