import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Layout, Form, Input, Button, Card, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import theme from '../theme';
import { AuthActions, AuthStore } from '../store/auth';

const LogoImage = '/logo.jpg'
const ForgotLink = styled(Link)`
    color:${props => props.theme.color.primary};

    :hover,:active,:focus{
        color:${props => props.theme.color.primaryHover};
    }
`

const SubmitButton = styled(Button)`
    background-color:${props => props.theme.color.primary};
    border: 1px solid ${props => props.theme.color.primary};
    border-radius:2.5rem;
    margin:1rem 0rem;
    width: 100%;
    text-shadow:none;

    :hover,:active,:focus{
        background-color:${props => props.theme.color.primaryHover};
        border: 1px solid ${props => props.theme.color.primaryHover};
    }
`

const LoginCard = styled(Card)`
    overflow:initial;
    justify-content:center;
    align-self: center;
    width:75%;
    border-radius:0.5rem;
    padding:0rem;
    text-align:-webkit-center;

    @media (min-width:992px){
        width:42rem;
    }
`

const LoginForm = styled(Form)`
    text-align: center;
    max-width: 18rem ;
`

const FormTitle = styled(Typography)`
    font-size:1.15rem;
    font-weight:400;
    text-align:left;
    margin:1rem 0rem;
`

const FormInput = styled(Input)`
    border-radius:0.25rem;
    border:${props => props.error ? '1px solid ' + theme.color.danger : '1px solid rgba(0,0,0,.125)'} !important;
    background-color:#f8f9fa;

    :focus{
        border:${props => props.error ? '1px solid ' + theme.color.danger : '1px solid ' + theme.color.info} !important;
        box-shadow: ${props => props.error ? '0 0 0 0.2rem rgba(210,35,70,.25)' : '0 0 0 0.2rem rgba(102,51,153,.25)'};
    }
`

const FormInputLabel = styled(Typography)`
    font-size: 0.75rem; 
    text-align: left;
    color: ${() => theme.color.mute};
    margin-top: 0.5rem;
`

const AlertText = styled(Typography)`
    color: ${() => theme.color.danger};
    font-weight: bold; 
    font-size: 0.65rem;
    text-align: left;
`

function LoginScreen() {
    const auth = AuthStore()

    const [form, setForm] = useState({
        email: '',
        password: '',
    })

    const [state, setState] = useState({
        error: null,
        loading: false,
    })

    const onFinish = async () => {
        setState({ ...state, loading: true })

        AuthActions.login(form).then(result => {
            auth.dispatch(result)
        }).catch(() => {
            setState({ error: 'These credentials do not match our records.', loading: false })
        })
    }

    useEffect(() => {
        setState({ error: null, loading: false })
    }, [form])

    return (
        <Layout style={{
            backgroundImage: 'url("/images/auth-bg.jpg")',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            minHeight: '100vh',
            justifyContent: 'center'
        }}>
            <LoginCard>
                <LoginForm
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Avatar
                        shape='circle'
                        src={LogoImage}
                        style={{
                            minWidth: '6rem',
                            minHeight: '6rem',
                        }}
                    />
                    <FormTitle>Sign In</FormTitle>
                    <FormInputLabel>Email address</FormInputLabel>
                    <FormInput value={form.email} onChange={e => setForm({ ...form, email: e.target.value })} error={state.error} />
                    {state.error && (
                        <AlertText>{state.error}</AlertText>
                    )}
                    <FormInputLabel>Password</FormInputLabel>
                    <FormInput type='password' value={form.password} onChange={e => setForm({ ...form, password: e.target.value })} />
                    <SubmitButton type="primary" htmlType="submit" loading={state.loading}>
                        Sign In
                    </SubmitButton>
                    <ForgotLink to={'/forgot'}>
                        Forgot Password
                    </ForgotLink>
                </LoginForm>
            </LoginCard>
        </Layout>
    );
};

export default LoginScreen