import axios from 'axios'
import env from '../config'
import * as auth from '../auth'

export async function list(page, page_total, start_date, end_date, status = 'all') {
    const token = await auth.getToken()
    return axios({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/teachers/bookings`,
        params: { page, page_total, start_date, end_date, status },
        // data: { email, password }
    })
}

export async function cancel(id) {
    const token = await auth.getToken()
    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/teachers/bookings/${id}/canceled`,
    })
}

export async function count(start_date, end_date) {
    const token = await auth.getToken()
    return axios({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/bookings/count`,
        params: { start_date, end_date }
    })
}

export async function create(data) {
    const token = await auth.getToken()
    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: '/teachers/bookings/create',
        data
    })
}

export async function detail(id) {
    const token = await auth.getToken()

    return axios({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/bookings/${id}`,
    })
}

export async function update(id, data) {
    const token = await auth.getToken()
    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/bookings/${id}`,
        data
    })
}