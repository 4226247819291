import axios from 'axios'
import env from '../config'
import * as auth from '../auth'

export async function search(text) {
    const token = await auth.getToken()
    return axios({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/teachers/users`,
        params: { search: text }
        // data: { email, password }
    })
}

export async function detail(id) {
    const token = await auth.getToken()
    return axios({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/teachers/users/${id}`,
        // data: { email, password }
    })
}

export async function ott() {
    const token = await auth.getToken()
    return axios({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/ott`,
        // data: { email, password }
    })
}