import { Avatar, Card, Col, Divider, Row, Spin, Tabs, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import {
    GoCalendar,
} from 'react-icons/go';
import {
    BsChevronDoubleRight
} from 'react-icons/bs'
import { useMediaQuery } from 'react-responsive'
import { AuthStore } from '../store/auth';
import dayjs from 'moment'
import theme from '../theme';
import { Link } from 'react-router-dom';
import { BookingStore } from '../store/bookings';
import HistoricalCheckin from './Home/HistoricalCheckin';
import HistoricalBooking from './Home/HistoricalBooking';

const { TabPane } = Tabs

const BookingButton = styled(Link)`
    min-height: 6rem;
    border: none;
    border-radius:10px;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
    text-align:left;
    padding: 1.5rem;
    width:100%;
    height:100%;
    display:block;
    /* padding:2rem; */
`

const Content = styled(Card)`
    margin-top:1rem;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
    border: none;
    border-radius:10px;
`

const ProfileTab = styled(Tabs)`
    .ant-tabs-nav::before{
        border-bottom: 1px solid ${() => theme.color.primary};
        /* border-bottom:1px solid #f0f0f0; */
    }

    .ant-tabs-tab{
        border:1px solid white !important;
        background:white !important;
        border-bottom: 1px solid ${() => theme.color.primary} !important;

        div{
            font-size:0.75rem;
            margin:0.25rem 0rem;
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active{
        border:1px solid ${() => theme.color.primary} !important;
        border-bottom: 1px solid #FFFFFF !important;
        background-color:rgba(102,51,153,.1) !important;
        filter:brightness(100%);
        div{
            color:#000000;
            font-weight:normal;
        }
    }


`

function HomeScreen() {
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })
    const { state: bookinState } = BookingStore()
    const authContext = AuthStore()

    return (
        <Row>
            <Col xs={24}>
                <Row>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <BookingButton to={'/bookings'}>
                            <div style={{ display: 'flex' }}>
                                <GoCalendar style={{ fontSize: '4rem', color: 'rgba(102,51,153,.28)' }} />
                                <div style={{ padding: '0rem 0.5rem', display: 'block' }}>
                                    <Typography style={{ color: '#70657b', fontSize: '1rem' }}>
                                        Booking
                                    </Typography>
                                    <Typography style={{ color: '#fed504', fontSize: '1.5rem' }}>
                                        {bookinState.loading ? (
                                            <Spin />
                                        ) : (
                                                <>{bookinState.total}</>
                                            )}
                                    </Typography>
                                </div >
                            </div>
                        </BookingButton>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Content style={{ padding: isMobile ? '0rem' : '1rem' }}>
                            <Row>
                                {!isMobile && (
                                    <Col lg={6} style={{ textAlign: '-webkit-center', color: '#47404f' }}>
                                        <Row>
                                            <Col xs={24}>
                                                <Avatar
                                                    style={{
                                                        backgroundImage: 'url("/images/avatars.jpg")',
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        objectFit: 'contain',
                                                        height: '250px',
                                                        width: '250px',
                                                        borderRadius: '50%',
                                                        margin: '1rem',
                                                        boxShadow: '0 1px 15px 1px rgba(0,0,0,.24), 0 1px 6px rgba(0,0,0,.04)'
                                                    }}
                                                    shape='circle'
                                                    src={authContext?.state?.user?.fullpath_img}
                                                />
                                            </Col>
                                        </Row>
                                        <Divider />
                                        <Row style={{ textAlign: 'center', padding: '1rem' }}>
                                            <Col xs={24}>
                                                <Typography style={{ fontSize: '1.5rem' }}>{authContext.state.user.fullname_en || authContext.state.user.fullname_th}</Typography>
                                                <Typography style={{ fontSize: '1rem', color: '#70657b' }}>{authContext.state.user.fullname_th || '-'}</Typography>
                                                <Row>
                                                    <Col xs={24}>
                                                        <Badge>{authContext.state.user.role_text}</Badge>
                                                        <Badge>{authContext.state.user.level_text}</Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                            <Col xs={14}>
                                                <Typography style={{ fontWeight: '600' }}>Gender</Typography>
                                            </Col>
                                            <Col xs={10}>
                                                <Typography>{authContext.state.user.gender_text || '-'}</Typography>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                            <Col xs={14}>
                                                <Typography style={{ fontWeight: '600' }}>Date of birth</Typography>
                                            </Col>
                                            <Col xs={10}>
                                                <Typography>{authContext.state.user.dob ? dayjs(authContext.state.user.dob).format('MMMM D, YY') : '-'}</Typography>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                            <Col xs={14}>
                                                <Typography style={{ fontWeight: '600' }}>E-mail</Typography>
                                            </Col>
                                            <Col xs={10}>
                                                <Typography>{authContext.state.user.email || '-'}</Typography>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                            <Col xs={14}>
                                                <Typography style={{ fontWeight: '600' }}>Telephone</Typography>
                                            </Col>
                                            <Col xs={10}>
                                                <Typography>{authContext.state.user.tel || '-'}</Typography>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                            <Col xs={14}>
                                                <Typography style={{ fontWeight: '600' }}>Line</Typography>
                                            </Col>
                                            <Col xs={10}>
                                                <Typography>{authContext.state.user.line_id || '-'}</Typography>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                            <Col xs={14}>
                                                <Typography style={{ fontWeight: '600' }}>Prefer Contact</Typography>
                                            </Col>
                                            <Col xs={10}>
                                                <Typography>{authContext.state.user.prefer_contact || '-'}</Typography>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                            <Col xs={14}>
                                                <Typography style={{ fontWeight: '600' }}>Occupation</Typography>
                                            </Col>
                                            <Col xs={10}>
                                                <Typography>{authContext.state.user.occupation || '-'}</Typography>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                            <Col xs={14}>
                                                <Typography style={{ fontWeight: '600' }}>Address</Typography>
                                            </Col>
                                            <Col xs={10}>
                                                <Typography>{authContext.state.user.address || '-'}</Typography>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                            <Col xs={14}>
                                                <Typography style={{ fontWeight: '600' }}>Session Received</Typography>
                                            </Col>
                                            <Col xs={10}>
                                                <Typography>-</Typography>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                            <Col xs={14}>
                                                <Typography style={{ fontWeight: '600' }}>Professional</Typography>
                                            </Col>
                                            {authContext.state.user.userpros && authContext.state.user.userpros.length > 0 ? (
                                                <Col xs={24} style={{ marginTop: '1rem' }}>
                                                    {authContext.state.user.userpros.map((up, key) => (
                                                        <Typography
                                                            key={key}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                fontSize: '.8rem'
                                                            }}
                                                        >
                                                            <BsChevronDoubleRight color={theme.color.success} style={{ marginRight: '0.5rem', marginTop: '0.25rem', marginBottom: '0.25rem' }} />
                                                            {up.professional.title}
                                                        </Typography>
                                                    ))}
                                                </Col>
                                            ) : (
                                                    <Col xs={10}>
                                                        <Typography>
                                                            -
                                                </Typography>
                                                    </Col>
                                                )}
                                        </Row>
                                    </Col>
                                )}
                                <Col xs={24} lg={18} style={{ padding: isMobile ? '0rem' : '1rem 0rem 0rem 3rem' }}>
                                    <ProfileTab type="card" onChange={(e) => {
                                        // (Number(e) === 1) ? setBookingList({ ...bookingList, loading: true }) : setCheckinList({ ...checkinList, loading: true })
                                    }}>
                                        <TabPane
                                            tab="Historical Booking"
                                            key="1"
                                        >
                                            <HistoricalBooking />
                                        </TabPane>
                                        <TabPane tab="Historical Check-in" key="2">
                                            <HistoricalCheckin />
                                        </TabPane>
                                    </ProfileTab>
                                </Col>
                            </Row>
                        </Content>
                    </Col>
                </Row>
            </Col>
        </Row >
    )
}

const Badge = styled.div`
    background: unset;
    border: 1px solid #263db5;
    color: #263db5;
    font-weight: 500;
    padding: .5rem!important;
    margin: .25rem!important;
    border-radius: 10rem;
    display: inline-block;
    font-size: 0.75rem;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    box-sizing: border-box;
    font-family: inherit;
`


export default HomeScreen
