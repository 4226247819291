import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Layout, Form, Input, Button, Card, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import theme from '../theme';
import { AuthActions } from '../store/auth';
import Swal from 'sweetalert2';

const LogoImage = '/logo.jpg'

const SubmitButton = styled(Button)`
    background-color:${props => props.theme.color.primary};
    border: 1px solid ${props => props.theme.color.primary};
    border-radius:2.5rem;
    margin:1rem 0rem;
    width: 100%;
    text-shadow:none;

    :hover,:active,:focus{
        background-color:${props => props.theme.color.primaryHover};
        border: 1px solid ${props => props.theme.color.primaryHover};
    }
`

const LoginCard = styled(Card)`
    overflow:initial;
    justify-content:center;
    align-self: center;
    width:75%;
    border-radius:0.5rem;
    padding:0rem;
    text-align:-webkit-center;

    @media (min-width:992px){
        width:42rem;
    }
`

const LoginForm = styled(Form)`
    text-align: center;
    max-width: 18rem ;
`

const FormTitle = styled(Typography)`
    font-size:1.15rem;
    font-weight:400;
    text-align:left;
    margin:1rem 0rem;
`

const FormInput = styled(Input)`
    border-radius:0.25rem;
    border:${props => props.error ? '1px solid ' + theme.color.danger : '1px solid rgba(0,0,0,.125)'} !important;
    background-color:#f8f9fa;

    :focus{
        border:${props => props.error ? '1px solid ' + theme.color.danger : '1px solid ' + theme.color.info} !important;
        box-shadow: ${props => props.error ? '0 0 0 0.2rem rgba(210,35,70,.25)' : '0 0 0 0.2rem rgba(102,51,153,.25)'};
    }
`

const FormInputLabel = styled(Typography)`
    font-size: 0.75rem; 
    text-align: left;
    color: ${() => theme.color.mute};
    margin-top: 0.5rem;
`

const AlertText = styled(Typography)`
    color: ${() => theme.color.danger};
    font-weight: bold; 
    font-size: 0.65rem;
    text-align: left;
`

function ResetScreen() {
    const history = useHistory()

    const params = new URLSearchParams(history.location.search)

    const [form, setForm] = useState({
        email: '',
        password: '',
        confirm_password: '',
        token: params.get('token'),
    })

    const [state, setState] = useState({
        error: null,
        loading: false,
    })

    const onFinish = async () => {
        if (form.confirm_password !== form.password) {
            return Swal.fire({
                icon: 'warning',
                title: 'Confirm password is wrong.'
            })
        }

        setState({ ...state, loading: true })

        AuthActions.reset(form).then(result => {
            setState({ ...state, loading: false })
            Swal.fire({
                icon: 'success',
                title: 'Reset password success',
                timer: 1000,
            }).then(() => {
                history.push('/')
            })
        }).catch((err) => {
            setState({ error: err.message, loading: false })
        })
    }

    useEffect(() => {
        setState({ error: null, loading: false })
    }, [form])

    return (
        <Layout style={{
            backgroundImage: 'url("/images/auth-bg.jpg")',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            minHeight: '100vh',
            justifyContent: 'center'
        }}>
            <LoginCard>
                <LoginForm
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Avatar
                        shape='square'
                        src={LogoImage}
                        style={{
                            minWidth: '6rem',
                            minHeight: '6rem',
                        }}
                    />
                    <FormTitle>Reset Password</FormTitle>
                    <FormInputLabel>Enter your login email.</FormInputLabel>
                    <FormInput value={form.email} type='email' required={true} onChange={e => setForm({ ...form, email: e.target.value })} error={state.error} />
                    {state.error && (
                        <AlertText>{state.error}</AlertText>
                    )}
                    <FormInputLabel>New password.</FormInputLabel>
                    <FormInput type='password' required={true} value={form.password} onChange={e => setForm({ ...form, password: e.target.value })} />

                    <FormInputLabel>Confirm new password.</FormInputLabel>
                    <FormInput type='password' required={true} value={form.confirm_password} onChange={e => setForm({ ...form, confirm_password: e.target.value })} />
                    <SubmitButton type="primary" htmlType="submit" loading={state.loading}>
                        Change Password
                    </SubmitButton>
                </LoginForm>
            </LoginCard>
        </Layout>
    );
};

export default ResetScreen
