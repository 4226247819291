import axios from 'axios'
import env from '../config'
import * as auth from '../auth'

export async function connect(line_token) {
    const token = await auth.getToken()
    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        baseURL: env.baseUrl,
        url: `/line/connect`,
        data: { token: line_token }
        // data: { email, password }
    })
}