import { Button, Col, Form, Modal, Row, Spin, Table, Typography } from 'antd'
import dayjs from 'moment'
import React, { useEffect, useState } from 'react'
import {
    RiCloseCircleLine,
    RiEditBoxLine,
} from 'react-icons/ri'
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Flatpickr from "react-flatpickr"
import api from '../../api'
import theme from '../../theme';
import { AuthStore } from '../../store/auth';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

function HistoricalBooking() {
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const authContext = AuthStore()
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })

    const [bookingList, setBookingList] = useState({
        loading: true,
        list: [],
        total: 0,
        page_total: 10,
        page: 0,
        start_date: dayjs().startOf('week'),
        end_date: dayjs().endOf('week'),
    })

    const columns = [
        {
            title: 'Date/Time',
            align: 'center',
            dataIndex: 'begined_at',
            width: 180,
            render: (begined_at, data) => {
                const startText = dayjs(begined_at).format('D-M-YYYY') === dayjs(data.finished_at).format('D-M-YYYY') ? dayjs(begined_at).format('D-M-YYYY') : dayjs(begined_at).format('D-M-YYYY HH:mm')
                const endText = dayjs(begined_at).format('D-M-YYYY') === dayjs(data.finished_at).format('D-M-YYYY') ? `${dayjs(begined_at).format('HH:mm')} - ${dayjs(data.finished_at).format('HH:mm')}` : dayjs(data.finished_at).format('D-M-YYYY HH:mm')
                return <>
                    <Typography>
                        {startText}
                    </Typography>
                    {dayjs(begined_at).format('D-M-YYYY') !== dayjs(data.finished_at).format('D-M-YYYY') && (
                        <Typography>-</Typography>
                    )}
                    <Typography>
                        {endText}
                    </Typography>
                </>
            }
        },
        {
            title: 'Appropriate',
            dataIndex: 'type',
            align: 'center',
            width: 100,
            render: (type) => {
                return type === 10 ? 'Lesson' : 'Rest'
            }
        },
        {
            title: 'Amount(Hour)',
            align: 'center',
            dataIndex: 'amount',
            width: 100,
            render: (amount, data) => data.type === 10 && amount * 0.5
        },
        {
            title: 'Student',
            width: 250,
            align: 'center',
            render: (data) => {
                return data.type === 10 && <Typography style={{ width: '100%', textAlign: 'left' }}>
                    {data.student?.user ? data.student.user.fullname_en || data.student.user.fullname_th : 'Non-Specify'}
                </Typography>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 150,
            align: 'center',
            render: (status, data) => {
                if (data.type === 10) {
                    if (status === 0) {
                        return <Badge style={{ backgroundColor: theme.color.white, color: theme.color.grey, border: 'none' }} >Waiting</Badge>
                    } else if (status === 1) {
                        return <Badge style={{ backgroundColor: theme.color.danger, color: '#FFFFFF', border: 'none' }}>Booked</Badge>
                    } else if (status === 2) {
                        return <Badge style={{ backgroundColor: theme.color.primary, color: '#FFFFFF', border: 'none' }}>Completed</Badge>
                    } else {
                        return <Badge style={{ backgroundColor: theme.color.grey, color: '#FFFFFF', border: 'none' }}>Canceled</Badge>
                    }
                } else {
                    if (status === 0) {
                        return <Badge style={{ backgroundColor: theme.color.white, color: theme.color.grey, border: 'none' }} >Waiting</Badge>
                    } else if (status === 1) {
                        return <Badge style={{ backgroundColor: theme.color.blue, color: '#FFFFFF', border: 'none' }}>Rest</Badge>
                    } else {
                        return <Badge style={{ backgroundColor: theme.color.grey, color: '#FFFFFF', border: 'none' }}>Canceled</Badge>
                    }
                }
            }
        },
    ]

    const handleCancelBooking = (id) => {
        setLoading(true)
        api.bookings.cancel(id).then(() => {
            const newState = bookingList.list.map(l => {
                if (l.token === id) {
                    l.status = 3
                }

                return l
            })

            setBookingList({ ...bookingList, list: newState })
            setLoading(false)
        })
    }

    useEffect(() => {
        if (bookingList.loading) {
            api.bookings.list(bookingList.page, bookingList.page_total, bookingList.start_date.startOf('day').format('YYYY-MM-DD HH:mm:ss'), bookingList.end_date.endOf('day').format('YYYY-MM-DD HH:mm:ss')).then(result => {
                // console.log(result)
                setBookingList({ ...bookingList, loading: false, list: result.data.list, total: result.data.total })
            })
        }
    }, [bookingList])

    return (
        <>
            <Typography style={{ fontSize: '1.25rem', marginBottom: '1rem' }}>
                Historical Booking
            </Typography>
            <Form onFinish={() => setBookingList({ ...bookingList, loading: true })} style={{ marginBottom: '1rem' }}>
                {authContext?.state?.user?.role_text === 'Teacher' && (
                    <Row>
                        <Col xs={24} style={{ textAlign: 'right', margin: isMobile ? '1rem 0rem' : '0rem' }}>
                            <Button htmlType='button' block={isMobile ? true : false} onClick={() => { history.push('/bookings') }}>+ Booking</Button>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs={24} md={8}>
                        <Flatpickr
                            options={{
                                time_24hr: true,
                                minuteIncrement: 15,
                                disableMobile: true,
                                mode: 'range',
                                enableTime: false,
                                dateFormat: 'd-m-Y',
                            }}
                            style={{
                                border: '1px solid #d9d9d9',
                                padding: '0.25rem 0.75rem',
                                width: '100%',
                                textAlign: 'center'
                            }}
                            defaultValue={dayjs().startOf('week').format('DD-MM-YYYY HH:mm:ss') + ' to ' + dayjs().endOf('week').format('DD-MM-YYYY HH:mm:ss')}
                            onChange={date => {
                                console.log(date[0])
                                const start = dayjs(date[0]).startOf('day')
                                const end = date.length > 1 ? dayjs(date[1]).endOf('day') : dayjs(date[0]).endOf('day')

                                setBookingList({ ...bookingList, start_date: start, end_date: end })
                            }}
                        />
                    </Col>
                    <Col xs={24} md={3}>
                        <Button block htmlType='submit'>Search</Button>
                    </Col>
                </Row>
            </Form>
            <Table
                rowKey='id'
                scroll={{ x: 800 }}
                dataSource={bookingList.list}
                loading={bookingList.loading}
                pagination={{
                    current: bookingList.page + 1,
                    total: bookingList.total,
                    pageSize: bookingList.page_total,
                    onChange(page, pageSize) {
                        setBookingList({ ...bookingList, loading: true, page: page - 1, page_total: pageSize })
                    }
                }}
                columns={authContext.state.user.role_text !== 'Teacher' ? columns : [
                    ...columns,
                    {
                        title: 'Action',
                        align: 'center',
                        width: 200,
                        render: (data) => {
                            return <>
                                <Button type='link' disabled={data.status === 2 || data.status === 3} onClick={() => {
                                    history.push(`/bookings/${data.token}`)
                                }}>
                                    <RiEditBoxLine />
                                </Button>
                                <Button type='link' disabled={data.status === 2 || data.status === 3} onClick={() => {
                                    Swal.fire({
                                        title: 'Do you want to cancel this booking?',
                                        icon: 'question',
                                        showDenyButton: true,
                                        // showCancelButton: true,
                                        confirmButtonText: `Yes`,
                                        denyButtonText: `No`,
                                    }).then(result => {
                                        if (result.isConfirmed) {
                                            handleCancelBooking(data.token)
                                        }
                                    })
                                    // if (window.confirm('Do you want to cancel this booking?')) {
                                    // handleCancelBooking(data.token)
                                    // }
                                }} >
                                    <RiCloseCircleLine color={data.status === 2 || data.status === 3 ? theme.color.grey : theme.color.danger} />
                                </Button>
                            </>
                        }
                    }
                ]}
            >
            </Table>
            <Modal title='Loading' visible={loading} footer={null} closable={false} bodyStyle={{ textAlign: 'center' }} centered>
                <Spin size='large' style={{ margin: '1rem 0rem' }} />
                <Typography style={{ fontSize: '1.5rem' }}>Please wait...</Typography>
            </Modal>
        </>
    )
}

const Badge = styled.div`
    background: unset;
    border: 1px solid #263db5;
    color: #263db5;
    font-weight: 500;
    padding: .5rem!important;
    margin: .25rem!important;
    border-radius: 10rem;
    display: inline-block;
    font-size: 0.75rem;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    box-sizing: border-box;
    font-family: inherit;
`

export default HistoricalBooking
