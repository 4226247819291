import axios from 'axios'
import env from '../config'
import * as auth from '../auth'

export function login(email, password) {
    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        baseURL: env.baseUrl,
        url: `/login`,
        data: { email, password, type: 'teacher' }
    })
}

export function forgot(email) {
    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        baseURL: env.baseUrl,
        url: `/forgot`,
        data: { email, redirect_url: `${window.location.origin}/reset` }
    })
}

export function reset(email, password, token) {
    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        baseURL: env.baseUrl,
        url: `/reset`,
        data: { email, password, token }
    })
}

export function token(token) {
    return axios({
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        baseURL: env.baseUrl,
        url: `/refresh`,
    })
}

export async function me() {
    const token = await auth.getToken()
    return await axios({
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        baseURL: env.baseUrl,
        url: `/me`,
    })
}